<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12" class="py-0">
        <v-card class="px-7 py-7 rounded-lg" id="card-custom">
          <p class="headline-color">Rekapitulasi Data Pegawai</p>
          <v-divider />
          <v-row cols="12" xl="12" lg="12" md="12" sm="12" xs="12" class="my-0">
            <v-col>
              <v-radio-group v-model="format" mandatory>
                <v-row>
                  <v-col
                    cols="4"
                    class="pb-0"
                    v-for="(item, index) in formats"
                    :key="index"
                  >
                    <v-radio
                      :value="item.value"
                      class="font-weight-regular"
                      hide-details
                    >
                      <template #label>
                        <span class="subtitle-2 font-weight-regular">{{
                          item.label
                        }}</span>
                      </template>
                    </v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row align="end">
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0"
              v-if="displayUnit"
            >
              <v-autocomplete
                :items="units"
                v-model="unit"
                :loading="loadingUnit"
                placeholder="Unit Utama"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_utama_id"
                clearable
                @change="changeUnit(1)"
                @click:clear="changeUnit(1)"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0"
              v-if="displayUnit"
            >
              <v-autocomplete
                :items="units2"
                v-model="unit2"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_2_id"
                clearable
                @change="changeUnit(2)"
                @click:clear="changeUnit(2)"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0"
              v-if="displayUnit"
            >
              <v-autocomplete
                :items="units3"
                v-model="unit3"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_3_id"
                clearable
                @change="changeUnit(3)"
                @click:clear="changeUnit(3)"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0"
              v-if="displayUnit"
            >
              <v-autocomplete
                :items="units4"
                v-model="unit4"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_4_id"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col
              ols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0"
              v-if="displayUnit"
            >
              <v-select
                placeholder="Unit Kerja Group"
                :items="unitsGroup"
                v-model="unit1"
                :loading="loadingUnit"
                outlined
                dense
                hide-details
                clearable
                item-text="nama_uk_group"
                item-value="uk_group_id"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0"
              v-if="displayDate"
            >
              <v-autocomplete
                :items="months"
                v-model="month"
                placeholder="Bulan"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0"
              v-if="displayDate"
            >
              <v-autocomplete
                :items="years"
                v-model="year"
                placeholder="Tahun"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0"
              v-if="displayKelompok"
            >
              <v-autocomplete
                placeholder="Kelompok Jabatan"
                v-model="kelompok"
                :items="kelompokList"
                :loading="loadingKelompok"
                outlined
                dense
                hide-details
                clearable
                item-text="nama_kel_jabatan"
                item-value="kel_jabatan_id"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0"
              v-if="displayPeriod"
            >
              <v-autocomplete
                placeholder="Periode"
                v-model="period"
                :items="periodList"
                outlined
                dense
                hide-details
                clearable
                item-text="label"
                item-value="value"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0"
              v-if="displayDetail"
            >
              <v-autocomplete
                placeholder="Detail"
                v-model="detail"
                :items="detailList"
                outlined
                dense
                hide-details
                clearable
                item-text="label"
                item-value="value"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0"
              v-if="displayEducationPeriod"
            >
              <v-autocomplete
                placeholder="Periode Pendidikan"
                v-model="educationPeriod"
                :items="educationPeriodList"
                outlined
                dense
                hide-details
                clearable
                item-text="label"
                item-value="value"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0"
              v-if="displayPendidikan"
            >
              <v-autocomplete
                placeholder="Pendidikan"
                v-model="pendidikan1"
                :loading="loadingPendidikan"
                :items="pendidikanList"
                outlined
                dense
                hide-details
                clearable
                item-text="nama_pend"
                item-value="kode_pend"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0"
              v-if="displayPendidikan"
            >
              <v-autocomplete
                placeholder="Pendidikan"
                v-model="pendidikan2"
                :loading="loadingPendidikan"
                :items="pendidikanList"
                outlined
                dense
                hide-details
                clearable
                item-text="nama_pend"
                item-value="kode_pend"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-btn color="#FBB005" class="px-10 mr-3" @click="handleSearch">
                <span class="subtitle-2 text-capitalize">Cari</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="visibleColumn = true"
              small
              outlined
              class="px-4 font-weight-regular"
              color="#555555"
            >
              <v-icon class="mr-2">view_column</v-icon>
              Sesuaikan Kolom
            </v-btn>
            <v-btn
              small
              outlined
              color="#717171"
              class="mx-2 px-5 text-capitalize"
              :loading="loadingExport"
              @click="exportDataPegawai"
              v-if="employees && employees.length > 0"
            >
              <v-icon class="mr-2">file_download</v-icon>
              Informasi Pegawai
            </v-btn>
          </v-card-actions>
          <v-row>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <v-data-table
                id="table-custom"
                class="mt-10"
                multi-sort
                :headers="headers"
                :items="employees"
                :loading="loading"
                loading-text="Loading... Please wait"
              >
                <template v-slot:[`item.NIP`]="{ item }">
                  <span
                    class="hover-primary"
                    @click="
                      getToPage('employee.detail', {
                        params: { employeeId: item.NIP }
                      })
                    "
                    >{{ item.NIP }}</span
                  >
                </template>
                <template v-slot:[`item.jabatan_id`]="{ item }">{{
                  item.jabatan
                }}</template>
                <template v-slot:[`item.unit_kerja_2_id`]="{ item }">{{
                  item.unit_kerja_2
                }}</template>
                <template v-slot:[`item.unit_kerja_3_id`]="{ item }">{{
                  item.unit_kerja_3
                }}</template>
                <template v-slot:[`item.unit_kerja_4_id`]="{ item }">{{
                  item.unit_kerja_4
                }}</template>
                <template v-slot:no-data>
                  <ContentNotFound
                    message="Data pegawai dan presensi tidak ada"
                  />
                </template>
              </v-data-table>
              <v-card-actions class="pa-0">
                <v-spacer />
                <p class="title mb-0">Total: {{ employeesTotal }}</p>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visibleColumn" persistent max-width="750">
      <v-card class="pa-5 rounded-lg">
        <v-btn @click="visibleColumn = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          <v-icon class="mr-2">view_column</v-icon>
          Sesuaikan Kolom
        </v-card-text>
        <v-divider />
        <v-row v-for="(groupName, index) in groupNames" :key="index">
          <v-col cols="12" class="pb-0">
            <p class="mb-0">{{ groupName | snakeToUppercase }}</p>
            <v-row>
              <v-col
                cols="3"
                class="pb-0"
                v-for="(item, index2) in headersTemp.filter(
                  data => data.group == groupName
                )"
                :key="index2"
              >
                <v-checkbox
                  v-model="item.active"
                  :value="item.value"
                  hide-details
                  dense
                >
                  <template #label>
                    <span class="subtitle-2 font-weight-regular">{{
                      item.text
                    }}</span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-divider class="my-5" />
          </v-col>
        </v-row>
        <v-card-actions class="mt-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#717171"
            class="px-12 subtitle-2 text-capitalize"
            @click="visibleColumn = false"
            >Batal</v-btn
          >
          <v-btn
            small
            color="#FBB005"
            class="px-12 subtitle-2 text-capitalize"
            @click="handleApply"
            >Buat</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import RekapitulasiService from "@/services/resources/rekapitulasi.service";
import DataUtamaService from "@/services/resources/data.utama.service";
const ContentNotFound = () => import("@/components/Content/NotFound");

export default {
  components: {
    ContentNotFound
  },
  data() {
    return {
      format: null,
      formats: [
        { label: "Kenaikan Pangkat", value: "kenaikan_pangkat" },
        { label: "Masa Kerja", value: "masa_kerja" },
        { label: "Pendidikan", value: "pendidikan" },
        { label: "Golongan", value: "golongan" },
        { label: "Jenis Kelamin", value: "jenis_kelamin" },
        { label: "Kebutuhan Pegawai", value: "kebutuhan_pegawai" },
        { label: "Eselon", value: "eselon" }
      ],
      visible: false,
      visibleFilter: false,
      visibleColumn: false,
      month: this.$moment().format("MMMM"),
      year: Number(this.$moment().format("YYYY")),
      months: [],
      years: [],
      loadingUnit: false,
      loadingExport: false,
      loadingKelompok: false,
      loadingPendidikan: false,
      unit: null,
      unit1: null,
      unit2: null,
      unit3: null,
      unit4: null,
      unitsGroup: [],
      units: [],
      units1: [],
      units2: [],
      units3: [],
      units4: [],
      kelompok: null,
      kelompokList: [],
      period: "all",
      periodList: [
        { label: "Semua", value: "all" },
        { label: "Sekarang", value: "now" },
        { label: "Sebelumnya", value: "before" }
      ],
      detail: "Y",
      detailList: [
        { label: "Ya", value: "Y" },
        { label: "Tidak", value: "N" }
      ],
      educationPeriod: "All",
      educationPeriodList: [
        { label: "Semua", value: "All" },
        { label: "Unit Kerja", value: "unit_kerja" },
        { label: "Kelompok Pendidikan", value: "kelompok_pend" },
        { label: "Jenis Pendidikan", value: "jk" }
      ],
      pendidikan1: null,
      pendidikan2: null,
      pendidikanList: [],
      headers: [],
      headersKenaikanPangkatActive: [
        "no",
        "UnitKerja",
        "I_a",
        "II_a",
        "III_a",
        "IV_a",
        "jml"
      ],
      headersActive: [],
      limitHeader: 5,
      groupNames: ["umum", "pangkat"],
      headersTemp: [],
      headersKenaikanPangkatData: [
        {
          text: "No",
          value: "no",
          sortable: false,
          align: "center"
        },
        {
          text: "UnitKerja",
          value: "UnitKerja",
          sortable: false,
          align: "center",
          group: "umum"
        },
        {
          text: "I_a",
          value: "I_a",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "I_b",
          value: "I_b",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "I_c",
          value: "I_c",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "I_d",
          value: "I_d",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "II_a",
          value: "II_a",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "II_b",
          value: "II_b",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "II_c",
          value: "II_c",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "II_d",
          value: "II_d",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "III_a",
          value: "III_a",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "III_b",
          value: "III_b",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "III_c",
          value: "III_c",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "III_d",
          value: "III_d",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "IV_a",
          value: "IV_a",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "IV_b",
          value: "IV_b",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "IV_c",
          value: "IV_c",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "IV_d",
          value: "IV_d",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "Jumlah",
          value: "jml",
          sortable: false,
          align: "center",
          group: "umum"
        }
      ],
      totalItem: 0,
      pageCount: 0,
      loading: false,
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["NIP"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      employees: [],
      employeesTotal: 0
    };
  },
  computed: {
    displayExport() {
      return (
        this.format == "masa_kerja" ||
        this.format == "kenaikan_pangkat" ||
        this.format == "golongan" ||
        this.format == "jenis_kelamin" ||
        this.format == "kebutuhan_pegawai" ||
        this.format == "eselon" ||
        this.format == "pendidikan"
      );
    },
    displayUnit() {
      return (
        this.format == "masa_kerja" ||
        this.format == "kenaikan_pangkat" ||
        this.format == "golongan" ||
        this.format == "jenis_kelamin" ||
        this.format == "kebutuhan_pegawai" ||
        this.format == "eselon" ||
        this.format == "pendidikan"
      );
    },
    displayDate() {
      return (
        this.format == "masa_kerja" ||
        this.format == "kenaikan_pangkat" ||
        this.format == "golongan" ||
        this.format == "jenis_kelamin" ||
        this.format == "kebutuhan_pegawai" ||
        this.format == "eselon" ||
        this.format == "pendidikan"
      );
    },
    displayKelompok() {
      return (
        this.format == "masa_kerja" ||
        this.format == "kenaikan_pangkat" ||
        this.format == "golongan" ||
        this.format == "jenis_kelamin" ||
        this.format == "kebutuhan_pegawai" ||
        this.format == "eselon" ||
        this.format == "pendidikan"
      );
    },
    displayDetail() {
      return this.format == "eselon";
    },
    displayPeriod() {
      return (
        this.format == "kenaikan_pangkat" ||
        this.format == "golongan" ||
        this.format == "jenis_kelamin"
      );
    },
    displayEducationPeriod() {
      return this.format == "pendidikan";
    },
    displayPendidikan() {
      return this.format == "pendidikan";
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getListEmployee);
      },
      deep: true
    },
    type(val) {
      if (val == 0) {
        this.search = null;
      } else if (val == 1) {
        this.unit = null;
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;
      }
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getListEmployee);
    },
    format(val) {
      if (val == "naikPangkat") {
        this.months = ["April", "Oktober"];
      } else {
        this.months = this.$moment.months();
      }
    }
  },
  methods: {
    changeUnit(type = 2) {
      if (type == 1) {
        this.units2 = this.units.find(
          d => d.unit_utama_id == this.unit
        )?.unit_kerja2;
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;

        // Unit Group
        this.units1 = this.unitsGroup.find(
          data => data.unit_utama_id == this.unit
        )?.unit_kerja_group;
      } else if (type == 2) {
        this.units3 = this.units2.find(
          d => d.unit_kerja_2_id == this.unit2
        )?.unit_kerja3;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 3) {
        this.units4 = this.units3.find(
          d => d.unit_kerja_3_id == this.unit3
        )?.unit_kerja4;
        this.unit4 = null;
      }
    },
    populateUnit() {
      this.units2 = this.units.find(d =>
        d.unit_utama_id.includes(this.unit)
      )?.unit_kerja2;
      this.units3 = this.units2.find(
        d => d.unit_kerja_2_id == this.unit2
      )?.unit_kerja3;
      this.units4 = this.units3.find(
        d => d.unit_kerja_3_id == this.unit3
      )?.unit_kerja4;
    },
    handleApply() {
      // const headerActive = this.headersTemp.filter(d => d.active != null);
      // if (headerActive.length > this.limitHeader) {
      //   this.$store.commit("snackbar/setSnack", {
      //     show: true,
      //     message: `Hanya bisa memunculkan maksimal ${this.limitHeader} column`,
      //     color: "error"
      //   });
      // } else {
      this.headers = this.headersTemp.filter(d => d.active != null);
      this.visibleColumn = false;
      // }
    },
    handleSearch() {
      this.getHeader();
      this.getListEmployee();
    },
    getHeader() {
      if (this.format == "kenaikan_pangkat") {
        this.headersActive = this.headersKenaikanPangkatActive;
        this.headers = this.headersKenaikanPangkatData
          .filter(d => this.headersActive.find(d2 => d2 == d.value))
          .map(d => {
            d.filterText = "";
            return d;
          });
        this.headersTemp = this.headersKenaikanPangkatData.map(d => {
          const selectedItem = this.headersActive.find(d2 => d2 == d.value);
          d.active = selectedItem ? d.value : null;
          return d;
        });
      }
    },
    // Service
    async getListUnitKerjaGroup() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitGroupList({
          filter: {
            search: null,
            unit_utama_id: null
          },
          orderBy: ["nama_uk_group", "asc"],
          pageCurrent: 1,
          dataPerPage: 100
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.unitsGroup = data.list;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.units = data;
              this.unit = data[0]?.unit_utama_id;
              this.getListEmployee();
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListEmployee() {
      const { sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      let serviceAPI = RekapitulasiService.getNaikPangkat;
      if (this.format == "masa_kerja") {
        serviceAPI = RekapitulasiService.getMasaKerja;
      } else if (this.format == "pendidikan") {
        serviceAPI = RekapitulasiService.getPendidikan;
      } else if (this.format == "golongan") {
        serviceAPI = RekapitulasiService.getGolongan;
      } else if (this.format == "jenis_kelamin") {
        serviceAPI = RekapitulasiService.getKelamin;
      } else if (this.format == "kebutuhan_pegawai") {
        serviceAPI = RekapitulasiService.getKebutuhanPegawai;
      } else if (this.format == "eselon") {
        serviceAPI = RekapitulasiService.getEselon;
      }

      const selectedIndex = this.months.findIndex(data => data == this.month);

      try {
        this.loading = true;
        await serviceAPI({
          unit_utama: this.displayUnit ? this.unit || "All" : undefined,
          unit_kerja_2_id: this.displayUnit ? this.unit2 || "All" : undefined,
          unit_kerja_3_id: this.displayUnit ? this.unit3 || "All" : undefined,
          unit_kerja_4_id: this.displayUnit ? this.unit4 || "All" : undefined,
          kelompok: this.displayKelompok ? this.kelompok || "All" : undefined,
          bulan: this.displayDate ? selectedIndex + 1 : undefined,
          tahun: this.displayDate ? this.year : undefined,
          periode: this.displayPeriod ? this.period : undefined,
          detail: this.displayDetail ? this.detail : undefined,
          periodependidikan: this.displayEducationPeriod
            ? this.educationPeriod
            : undefined,
          pendidikan1: this.displayPendidikan ? this.pendidikan1 : undefined,
          pendidikan2: this.displayPendidikan ? this.pendidikan2 : undefined
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, total } = data;
            if (status) {
              let employees = list;
              let totalCount = 0;
              employees.map((data, index) => {
                data.no = index + 1;
                totalCount = totalCount + parseInt(data.jml);
                return data;
              });

              this.employees = employees;
              this.employeesTotal = totalCount;
              this.pagination.totalItem = parseInt(total);
              this.pagination.pageCount = parseInt(total / 10);
            }
          })
          .catch(error => {
            this.$store.commit("snackbar/setSnack", {
              show: true,
              message:
                "Gagal di tampilkan, periksa kembali filter yang digunakan",
              color: "error"
            });
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async exportDataPegawai() {
      const { sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });

      let serviceAPI = RekapitulasiService.exportNaikPangkat;
      if (this.format == "masa_kerja") {
        serviceAPI = RekapitulasiService.exportMasaKerja;
      } else if (this.format == "pendidikan") {
        serviceAPI = RekapitulasiService.exportPendidikan;
      } else if (this.format == "golongan") {
        serviceAPI = RekapitulasiService.exportGolongan;
      } else if (this.format == "jenis_kelamin") {
        serviceAPI = RekapitulasiService.exportKelamin;
      } else if (this.format == "kebutuhan_pegawai") {
        serviceAPI = RekapitulasiService.exportKebutuhanPegawai;
      } else if (this.format == "eselon") {
        serviceAPI = RekapitulasiService.exportEselon;
      }

      const selectedIndex = this.months.findIndex(data => data == this.month);

      try {
        this.loadingExport = true;
        await serviceAPI({
          unit_utama: this.displayUnit ? this.unit || "All" : undefined,
          unit_kerja_2_id: this.displayUnit ? this.unit2 || "All" : undefined,
          unit_kerja_3_id: this.displayUnit ? this.unit3 || "All" : undefined,
          unit_kerja_4_id: this.displayUnit ? this.unit4 || "All" : undefined,
          kelompok: this.displayKelompok ? this.kelompok || "All" : undefined,
          bulan: this.displayDate ? selectedIndex + 1 : undefined,
          tahun: this.displayDate ? this.year : undefined,
          periode: this.displayPeriod ? this.period : undefined,
          detail: this.displayDetail ? this.detail : undefined,
          periodependidikan: this.displayEducationPeriod
            ? this.educationPeriod
            : undefined,
          pendidikan1: this.displayPendidikan ? this.pendidikan1 : undefined,
          pendidikan2: this.displayPendidikan ? this.pendidikan2 : undefined
        })
          .then(response => {
            const { status, data, message } = response.data;
            if (status) {
              const fileName = data.split("/").slice(-1);
              const link = document.createElement("a");
              link.href = data;
              link.setAttribute("download", `${fileName}`);
              document.body.appendChild(link);
              link.click();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message,
                color: "error"
              });
            }
          })
          .catch(error => {
            this.$store.commit("snackbar/setSnack", {
              show: true,
              message:
                "Gagal di tampilkan, periksa kembali filter yang digunakan",
              color: "error"
            });
            throw new Error(error);
          })
          .finally(() => (this.loadingExport = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    if (this.type == 1) {
      this.unit = null;
      this.unit2 = null;
      this.unit3 = null;
      this.unit4 = null;
    }
    this.getHeader();
    this.getListUnit();
    this.getListUnitKerjaGroup();
    if (this.$route.query && this.$route.query.tipe) {
      this.format = this.$route.query.tipe;
    }

    const years = back => {
      const year = new Date().getFullYear();
      return Array.from({ length: back }, (v, i) => year - back + i + 1);
    };

    this.years = years(3);
    this.months = this.$moment.months();
  }
};
</script>
<style lang="scss">
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
#card-custom .v-label {
  font-size: 0.775rem;
}
.v-treeview {
  font-size: 0.775rem;
}
</style>
