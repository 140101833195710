import BaseInstance from "../base.instance";
import {
  SIMPEG_REKAPITULASI_NAIK_PANGKAT,
  SIMPEG_REKAPITULASI_MASA_KERJA,
  SIMPEG_REKAPITULASI_PENDIDIKAN,
  SIMPEG_REKAPITULASI_GOLONGAN,
  SIMPEG_REKAPITULASI_KELAMIN,
  SIMPEG_REKAPITULASI_KEBUTUHAN_PEGAWAI,
  SIMPEG_REKAPITULASI_ESELON,
  SIMPEG_REKAPITULASI_NAIK_PANGKAT_EXPORT,
  SIMPEG_REKAPITULASI_MASA_KERJA_EXPORT,
  SIMPEG_REKAPITULASI_PENDIDIKAN_EXPORT,
  SIMPEG_REKAPITULASI_GOLONGAN_EXPORT,
  SIMPEG_REKAPITULASI_KELAMIN_EXPORT,
  SIMPEG_REKAPITULASI_KEBUTUHAN_PEGAWAI_EXPORT,
  SIMPEG_REKAPITULASI_ESELON_EXPORT
} from "../constants";

const RecapService = {
  getNaikPangkat(params) {
    return BaseInstance.query(SIMPEG_REKAPITULASI_NAIK_PANGKAT, { params });
  },
  getMasaKerja(params) {
    return BaseInstance.query(SIMPEG_REKAPITULASI_MASA_KERJA, { params });
  },
  getPendidikan(params) {
    return BaseInstance.query(SIMPEG_REKAPITULASI_PENDIDIKAN, { params });
  },
  getGolongan(params) {
    return BaseInstance.query(SIMPEG_REKAPITULASI_GOLONGAN, { params });
  },
  getKelamin(params) {
    return BaseInstance.query(SIMPEG_REKAPITULASI_KELAMIN, { params });
  },
  getKebutuhanPegawai(params) {
    return BaseInstance.query(SIMPEG_REKAPITULASI_KEBUTUHAN_PEGAWAI, {
      params
    });
  },
  getEselon(params) {
    return BaseInstance.query(SIMPEG_REKAPITULASI_ESELON, { params });
  },
  exportNaikPangkat(params) {
    return BaseInstance.query(SIMPEG_REKAPITULASI_NAIK_PANGKAT_EXPORT, {
      params
    });
  },
  exportMasaKerja(params) {
    return BaseInstance.query(SIMPEG_REKAPITULASI_MASA_KERJA_EXPORT, {
      params
    });
  },
  exportPendidikan(params) {
    return BaseInstance.query(SIMPEG_REKAPITULASI_PENDIDIKAN_EXPORT, {
      params
    });
  },
  exportGolongan(params) {
    return BaseInstance.query(SIMPEG_REKAPITULASI_GOLONGAN_EXPORT, { params });
  },
  exportKelamin(params) {
    return BaseInstance.query(SIMPEG_REKAPITULASI_KELAMIN_EXPORT, { params });
  },
  exportKebutuhanPegawai(params) {
    return BaseInstance.query(SIMPEG_REKAPITULASI_KEBUTUHAN_PEGAWAI_EXPORT, {
      params
    });
  },
  exportEselon(params) {
    return BaseInstance.query(SIMPEG_REKAPITULASI_ESELON_EXPORT, { params });
  }
};

export default RecapService;
